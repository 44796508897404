import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface HeadingProps {
  children?: React.ReactNode;
  tag?: ModifierProp<'h1' | 'h2' | 'h3'>;
  size?: ModifierProp<'large'>;
  modifiers?: ModifierProp<'dot' | 'line'>;
}

export const Heading: React.FC<HeadingProps> = ({ children, tag, size, modifiers }) => {
  let tagName = 'span';
  if (tag) {
    tagName = tag as string;
  }
  const CustomTag = tagName as keyof JSX.IntrinsicElements;
  return <CustomTag className={mapModifiers('a-heading', size, modifiers)}>{children}</CustomTag>;
};
