import React, { useEffect, useRef, useState, useCallback } from 'react';
import { mapModifiers } from 'libs/component';
import { useMediaQuery } from 'libs/use-media-query';

export interface MaskImageProps {
  children?: React.ReactNode;
  video?: React.ReactNode;
  start?: boolean;
}

export const MaskImage: React.FC<MaskImageProps> = ({ children, video, start: initStart = false }) => {
  const [isBlend, setIsBlend] = useState(initStart);
  const [isPlayed, setIsPlayed] = useState(initStart);
  const videoRef = useRef(null);
  const isSP = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setIsBlend(initStart);
    setIsPlayed(initStart);
  }, [initStart]);

  const handleVideoEnded = useCallback(() => {
    requestAnimationFrame(() => {
      setIsBlend(false);
    });
  }, []);

  useEffect(() => {
    // get video
    const videoHolderEl: HTMLDivElement = videoRef.current as unknown as HTMLDivElement;
    const videoEl = videoHolderEl?.querySelector('video');
    if (videoEl) {
      videoEl.addEventListener('ended', handleVideoEnded);
    }
    return () => {
      if (videoEl) {
        videoEl.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [handleVideoEnded]);

  useEffect(() => {
    if (isSP) {
      setIsPlayed(true);
    } else {
      // get video
      const videoHolderEl: HTMLDivElement = videoRef.current as unknown as HTMLDivElement;
      const videoEl: HTMLVideoElement = videoHolderEl?.querySelector('video') as unknown as HTMLVideoElement;
      if (videoEl) {
        if (isBlend) {
          if (!videoEl.muted) {
            videoEl.muted = true;
          }
          videoEl.pause();
          videoEl.play().catch();
        }
      }
    }
  }, [isBlend, isSP]);

  return (
    <div className={mapModifiers('a-mask-image', isBlend && 'blending', isPlayed && 'played')}>
      {children}
      <div ref={videoRef} className="a-mask-image__video">
        {video}
      </div>
    </div>
  );
};
